// Stimulus controllers
import { startApplication } from './stimulus_controllers/application';
import { definitions } from 'stimulus:./stimulus_controllers';

export const application = startApplication(document.documentElement);
application.load(definitions);

// Modules
import './modules/turbo_frame';
import './modules/turbo_stream';
