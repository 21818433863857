import { Controller } from '@hotwired/stimulus';
import { zxcvbnAsync, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import { matcherPwnedFactory } from '@zxcvbn-ts/matcher-pwned';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static values = {
    labels: Object,
  };
  static targets = ['passwordInput', 'badge', 'scoreIcon', 'scoreIconDot', 'breachedIcon', 'label'];

  initialize() {
    // Note that there are also options to import huge language packages
    // but HaveIbeenpwned should already give a reasonable level of protection
    zxcvbnOptions.setOptions({
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
      },
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
    });
    const matcherPwned = matcherPwnedFactory(fetch, zxcvbnOptions);
    zxcvbnOptions.addMatcher('pwned', matcherPwned);
    this.scoreColors = {
      0: { dot: 'bg-gray-600', badge: 'bg-gray-200', label: 'text-gray-600' },
      1: { dot: 'bg-red-600', badge: 'bg-red-100', label: 'text-red-700' },
      2: { dot: 'bg-orange-500', badge: 'bg-orange-100', label: 'text-orange-600' },
      3: { dot: 'bg-green-500', badge: 'bg-green-100', label: 'text-green-600' },
      4: { dot: 'bg-green-800', badge: 'bg-green-100', label: 'text-green-800' },
    };
  }

  connect() {
    $(this.passwordInputTarget).on('input', (event) => this.updatePasswordStrengthMeter());
  }

  showLabelForScore(score) {
    const scoreColors = this.scoreColors[score];

    this.breachedIconTarget.classList.add('hidden');
    this.scoreIconTarget.classList.remove('hidden');
    const prevBadgeColor = [...this.badgeTarget.classList.values()].find((className) => className.startsWith('bg-'));
    const prevDotColor = [...this.scoreIconDotTarget.classList.values()].find((className) => className.startsWith('bg-'));
    this.badgeTarget.classList.replace(prevBadgeColor, scoreColors.badge);
    this.scoreIconDotTarget.classList.replace(prevDotColor, scoreColors.dot);

    this.labelTarget.textContent = this.labelsValue[`score_${score}`];
    this.labelTarget.className = '';
    this.labelTarget.classList.add(scoreColors.label);
  }

  showLabelBreached() {
    this.scoreIconTarget.classList.add('hidden');
    this.breachedIconTarget.classList.remove('hidden');
    const prevBadgeColor = [...this.badgeTarget.classList.values()].find((className) => className.startsWith('bg-'));
    this.badgeTarget.classList.replace(prevBadgeColor, 'bg-yellow-200');

    this.labelTarget.textContent = this.labelsValue.breached;
    this.labelTarget.className = '';
    this.labelTarget.classList.add('text-yellow-900');
  }

  originalUpdatePasswordStrengthMeter() {
    const password = this.passwordInputTarget.value;
    if (password.length < 8) {
      this.showLabelForScore(0);
    } else {
      // Note: Since this needs crypto.subtle, you can only test it locally if you
      // use 'localhost' as a host name, since otherwise it only works on https
      zxcvbnAsync(password).then((result) => {
        const score = result.score;
        const isPwned = result.sequence.some((sequence) => sequence.pattern === 'pwned');
        isPwned ? this.showLabelBreached() : this.showLabelForScore(score);
      });
    }
  }

  updatePasswordStrengthMeter = debounce(this.originalUpdatePasswordStrengthMeter, 300);
}
